export const USER_ACTION_TYPES = {
  CHECK_USER_SESSION: "user/CHECK_USER_SESSION",
  AUTH_CHECK_EMPTY: "user/AUTH_CHECK_EMPTY",

  GOOGLE_SIGN_IN_START: "user/GOOGLE_SIGN_IN_START",

  EMAIL_SIGN_IN_START: "user/EMAIL_SIGN_IN_START",
  SIGN_IN_SUCCESS: "user/SIGN_IN_SUCCESS",
  SIGN_IN_FAILURE: "user/SIGN_IN_FAILURE",

  SIGN_UP_START: "user/SIGN_UP_START",
  SIGN_UP_SUCCESS: "user/SIGN_UP_SUCCESS",
  SIGN_UP_FAILURE: "user/SIGN_UP_FAILURE",

  SIGN_OUT_START: "user/SIGN_OUT_START",
  SIGN_OUT_SUCCESS: "user/SIGN_OUT_SUCCESS",
  SIGN_OUT_FAILURE: "user/SIGN_OUT_FAILURE",

  MANAGE_CHECKOUT_USER_START: "user/MANAGE_CHECKOUT_USER_START",
  UPDATE_USER_SUCCESS: "user/UPDATE_USER_SUCCESS",
  UPDATE_USER_FAILURE: "user/UPDATE_USER_FAILURE",
  MANAGE_CHECKOUT_USER_SUCCESS: "user/MANAGE_CHECKOUT_USER_SUCCESS",
};
