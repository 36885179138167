import { auth } from "../../utils/firebase/firebase.utils";
import { onAuthStateChanged } from "firebase/auth";
import axios from "axios";

export function subscribeToAuthStateChange(callback) {
  return onAuthStateChanged(auth, (user) => {
    if (user) {
      user.getIdToken().then((idToken) => {
        callback({ uid: user.uid, idToken });
      });
    } else {
      callback(null);
    }
  });
}

// export const fetchAuth = async (idToken) => {
//   try {
//     const response = await axios({
//       url: "https://kal.porousway.com/auth",
//       method: "get",
//       headers: {
//         Authorization: `Bearer ${idToken}`,
//       },
//     });
//     return response;
//   } catch (error) {
//     console.error(error);
//     throw error;
//   }
// };

export const fetchAvailableSlots = async (slotType, housingType) => {
  try {
    const availableSlots = await axios({
      url: "https://kal.porousway.com/available_slots",
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        slot_type: slotType,
      },
    });
    if (housingType == "Condo") {
      availableSlots.data = availableSlots.data.filter((slot) =>
        slot.start.includes("09:00:00+08:00")
      );
    }
    return availableSlots.data;
  } catch (error) {
    throw error;
  }
};

export const bookSlot = async (orderId, cartItem, user) => {
  try {
    const response = await axios({
      url: "https://kal.porousway.com/book_slot",
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        ...cartItem,
        order_id: orderId,
        status: "paid",
        customer_name: user.firstName + " " + user.lastName,
        address: user.address,
        postal_code: user.postalCode,
        phone_number: user.phoneNumber,
        slot_type: cartItem.total_days,
        additional_notes: user.additionalNotes,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};
