import { takeLatest, all, call, put, select } from "redux-saga/effects";

import {
  getProducts,
  getMapProductsContent,
  getContentAndOptions,
} from "../../utils/firebase/firebase.utils";

import { fetchProductsSuccess, fetchProductsFailure } from "./products.action";

import { PRODUCTS_ACTION_TYPES } from "./products.types";

import {
  selectProductDataTimestamps,
  selectProducts,
  selectMap,
  selectContentAndOptions,
} from "./products.selector";

export function* fetchProductsAsync() {
  try {
    const timestamps = yield select(selectProductDataTimestamps);

    // Check if data is outdated. Data is considered outdated after 3 days.
    const shouldFetchProducts =
      !timestamps.products || Date.now() - timestamps.products > 259200000;

    const shouldFetchMapProductsContent =
      !timestamps.mapProductsContent ||
      Date.now() - timestamps.mapProductsContent > 259200000;

    const shouldFetchContentAndOptions =
      !timestamps.contentAndOptions ||
      Date.now() - timestamps.contentAndOptions > 259200000;

    // If data is not outdated, use the existing data from the Redux store.
    if (
      !shouldFetchProducts &&
      !shouldFetchMapProductsContent &&
      !shouldFetchContentAndOptions
    ) {
      // Dispatch fetchProductsSuccess with the existing data
      const products = yield select(selectProducts);
      const mapProductsContent = yield select(selectMap);
      const contentAndOptions = yield select(selectContentAndOptions);

      yield put(
        fetchProductsSuccess({
          products,
          mapProductsContent,
          contentAndOptions,
        })
      );
    }

    // If data is outdated, call async functions to retrieve data from Firestore.
    const products = shouldFetchProducts ? yield call(getProducts) : null;
    const mapProductsContent = shouldFetchMapProductsContent
      ? yield call(getMapProductsContent)
      : null;
    const contentAndOptions = shouldFetchContentAndOptions
      ? yield call(getContentAndOptions)
      : null;

    // Update the Redux store only if new data was fetched.
    if (products || mapProductsContent || contentAndOptions) {
      yield put(
        fetchProductsSuccess({
          products: products || [],
          mapProductsContent: mapProductsContent || [],
          contentAndOptions: contentAndOptions || [],
        })
      );
    }
  } catch (error) {
    yield put(fetchProductsFailure(error));
  }
}

export function* onFetchProducts() {
  yield takeLatest(
    PRODUCTS_ACTION_TYPES.FETCH_PRODUCTS_START,
    fetchProductsAsync
  );
}

export function* productsSaga() {
  yield all([call(onFetchProducts)]);
}
