import { createSelector } from "reselect";

const selectHalfDaySlotsReducer = (state) => state.halfDaySlots;

// Select array of halfDaySlots
export const selectHalfDaySlots = createSelector(
  [selectHalfDaySlotsReducer],
  (halfDaySlotsSlice) => halfDaySlotsSlice.halfDaySlots
);

// Select halfDaySlotsDataTimestamp value
export const selectHalfDaySlotsDataTimestamp = createSelector(
  [selectHalfDaySlotsReducer],
  (halfDaySlotsSlice) => halfDaySlotsSlice.halfDaySlotsDataTimestamp
);
