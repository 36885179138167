import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";

// To delete all the photos from project once done.
import poster from "../../media/poster.jpg";
import details from "../../media/details.jpg";
import qna from "../../media/qna.jpg";
import tnc from "../../media/tnc.pdf";

const Giveaway = () => {
  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h3">RAYA Giveaway Draw 2024</Typography>

      <Typography variant="h6" marginTop="10px">
        Link to our Raya Giveaway draw Facebook post:{" "}
      </Typography>
      <Link
        href="https://www.facebook.com/Porouswaysg/posts/pfbid029BjWYCpoZqv8kdLyHnqhFYMUqL9ew1ftTcujPzkTFDmELpqHe8A6pVnWgc8AtUG4l"
        underline="none"
        sx={{
          color: "#4da07c",
          fontSize: "28px",
          display: "flex",
          wordBreak: "break-all",
        }}
      >
        https://www.facebook.com/Porouswaysg/posts/pfbid029BjWYCpoZqv8kdLyHnqhFYMUqL9ew1ftTcujPzkTFDmELpqHe8A6pVnWgc8AtUG4l
      </Link>

      <Typography variant="h6" marginTop="10px">
        ONLY 2 simple steps to join:
      </Typography>
      <Typography>1. Like and Share the FB post (Public Post)</Typography>
      <Typography> 2. Comment “I want to win this” and tag 2 friends in the comments section</Typography>
      <Typography variant="h6" marginTop="10px">
        How to join (tutorial):
      </Typography>
      <Link
        href="https://youtube.com/shorts/AC_pcRPmwVk?feature=share"
        underline="none"
        sx={{
          fontSize: "20px",
          display: "flex",
          wordBreak: "break-all",
        }}
      >
        {" "}
        https://youtube.com/shorts/AC_pcRPmwVk?feature=share{" "}
      </Link>
      <Typography variant="h6" marginTop="10px">
        For showroom visits (by appointment){" "}
      </Typography>
      <Typography>Contact: 8825 8000 </Typography>
      <Typography>
        Address: 153 Kampong Ampat, #03-03 Jun Jie Industrial Building,
        Singapore 368326
      </Typography>

      <Grid container spacing={2} style={{ padding: "30px 0px" }}>
        <Grid item sm={12} md={8}>
          <img
            src={poster}
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </Grid>
        <Grid
          item
          sm={12}
          md={4}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <img
            src={details}
            style={{
              width: "100%",
              flex: 1,
              objectFit: "cover",
              marginBottom: "15px",
            }}
          />
          <img
            src={qna}
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </Grid>
      </Grid>

      <Typography variant="h5" marginBottom="15px">
        Terms and Conditions
      </Typography>
      <Typography marginBottom="10px">
        Download the Terms and Conditions{" "}
        <Link href={tnc} download>
          here
        </Link>
        .
      </Typography>
    </Box>
  );
};

export default Giveaway;
