import { useLocation } from "react-router-dom";

import { trackEvent } from "../../utils/firebase/firebase.utils";

import Fab from "@mui/material/Fab";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

const WhatsappButton = () => {
  const location = useLocation();

  const handleClick = () => {
    // Track clicks on Whatsapp Button in Google Analytics events and from which path people click it
    const transformedString = location.pathname
      .replace(/[^a-zA-Z0-9]+/g, "_")
      .toLowerCase();

    trackEvent("whatsapp_button" + transformedString);
  };
  return (
    <Fab
      color="primary"
      aria-label="open-chat"
      style={{
        position: "fixed",
        bottom: "16px",
        right: "16px",
        backgroundColor: "#25D366",
      }}
      href="https://wa.me/6588258000"
      onClick={handleClick}
    >
      <WhatsAppIcon />
    </Fab>
  );
};

export default WhatsappButton;
