import { takeLatest, all, call, put, select } from "redux-saga/effects";

import { fetchAvailableSlots } from "../../utils/gcalApi/gcalApi.utils";

import {
  fetchHalfDaySlotsSuccess,
  fetchHalfDaySlotsFailure,
} from "./half-day-slots.action";

import { HALF_DAY_SLOTS_ACTION_TYPES } from "./half-day-slots.types";

import {
  selectHalfDaySlots,
  selectHalfDaySlotsDataTimestamp,
} from "./half-day-slots.selector";

export function* fetchHalfDaySlotsAsync() {
  try {
    const timestamp = yield select(selectHalfDaySlotsDataTimestamp);
    
    // Check if data is outdated. Data is considered outdated after 3 days.
    const shouldFetchHalfDaySlots =
      !timestamp || Date.now() - timestamp > 259200000;

    // If data is not outdated, use the existing data from the Redux store.
    if (!shouldFetchHalfDaySlots) {
      const halfDaySlots = yield select(selectHalfDaySlots);
      yield put(fetchHalfDaySlotsSuccess(halfDaySlots));
    }

    // If data is outdated and new data was fetched, update Redux store.
    const halfDaySlots = yield call(fetchAvailableSlots, 0.5, "HDB");
    yield put(fetchHalfDaySlotsSuccess(halfDaySlots));
  } catch (error) {
    yield put(fetchHalfDaySlotsFailure(error));
  }
}

export function* onFetchHalfDaySlots() {
  yield takeLatest(
    HALF_DAY_SLOTS_ACTION_TYPES.FETCH_HALF_DAY_SLOTS_START,
    fetchHalfDaySlotsAsync
  );
}

export function* halfDaySlotsSaga() {
  yield all([call(onFetchHalfDaySlots)]);
}
