import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { format } from "date-fns";

import { selectHalfDaySlots } from "../../store/half-day-slots/half-day-slots.selector";
import { fetchHalfDaySlotsStart } from "../../store/half-day-slots/half-day-slots.action";

import Typography from "@mui/material/Typography";

const HalfDaySlots = () => {
  const dispatch = useDispatch();

  const halfDaySlots = useSelector(selectHalfDaySlots);

  useEffect(() => {
    dispatch(fetchHalfDaySlotsStart());
  }, []);

  const formatDateTime = (isoDateTimeString) => {
    const dateTime = new Date(isoDateTimeString);
    const formattedDateTime = format(dateTime, "dd MMM yyyy, h:mma");
    return formattedDateTime;
  };

  return (
    <>
      {!halfDaySlots.length == 0 && (
        <Typography>
          The next half-day slot is: {formatDateTime(halfDaySlots[0].start)}
        </Typography>
      )}
    </>
  );
};

export default HalfDaySlots;
