import { combineReducers } from "redux";

import { userReducer } from "./user/user.reducer";
import { productsReducer } from "./products/products.reducer";
import { cartReducer } from "./cart/cart.reducer";
import { halfDaySlotsReducer } from "./half-day-slots/half-day-slots.reducer";

export const rootReducer = combineReducers({
  user: userReducer,
  products: productsReducer,
  cart: cartReducer,
  halfDaySlots: halfDaySlotsReducer,
});
