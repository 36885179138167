import { CART_ACTION_TYPES } from "./cart.types";

export const CART_INITIAL_STATE = {
  cartItems: [],
  isLoading: false,
  error: null,
};

export const cartReducer = (state = CART_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case CART_ACTION_TYPES.SET_CART_ITEMS:
      return {
        ...state,
        cartItems: payload,
      };

    case CART_ACTION_TYPES.FETCH_AVAILABLE_SLOTS_START:
      return { ...state, isLoading: true };

    case CART_ACTION_TYPES.FETCH_AVAILABLE_SLOTS_SUCCESS:
      return {
        ...state,
        cartItems: payload,
        isLoading: false,
      };

    case CART_ACTION_TYPES.FETCH_AVAILABLE_SLOTS_FAILURE:
      return {
        ...state,
        error: payload,
        isLoading: false,
      };

    default:
      return state;
  }
};
