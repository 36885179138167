import { HALF_DAY_SLOTS_ACTION_TYPES } from "./half-day-slots.types";

export const HALF_DAY_SLOTS_INITIAL_STATE = {
  halfDaySlots: [],
  halfDaySlotsDataTimestamp: null,
  isLoading: false,
  error: null,
};

export const halfDaySlotsReducer = (
  state = HALF_DAY_SLOTS_INITIAL_STATE,
  action = {}
) => {
  const { type, payload } = action;

  switch (type) {
    case HALF_DAY_SLOTS_ACTION_TYPES.FETCH_HALF_DAY_SLOTS_START:
      return { ...state, isLoading: true };

    case HALF_DAY_SLOTS_ACTION_TYPES.FETCH_HALF_DAY_SLOTS_SUCCESS:
      return {
        ...state,
        halfDaySlots: payload,
        halfDaySlotsDataTimestamp: Date.now(),
        isLoading: false,
      };

    case HALF_DAY_SLOTS_ACTION_TYPES.FETCH_HALF_DAY_SLOTS_FAILURE:
      return {
        ...state,
        error: payload,
        isLoading: false,
      };

    default:
      return state;
  }
};
