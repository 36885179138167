import { HALF_DAY_SLOTS_ACTION_TYPES } from "./half-day-slots.types";
import { createAction } from "../../utils/reducer/reducer.utils";

export const fetchHalfDaySlotsStart = () =>
  createAction(HALF_DAY_SLOTS_ACTION_TYPES.FETCH_HALF_DAY_SLOTS_START);

export const fetchHalfDaySlotsSuccess = (halfDaySlots) =>
  createAction(
    HALF_DAY_SLOTS_ACTION_TYPES.FETCH_HALF_DAY_SLOTS_SUCCESS,
    halfDaySlots
  );

export const fetchHalfDaySlotsFailure = (error) =>
  createAction(HALF_DAY_SLOTS_ACTION_TYPES.FETCH_HALF_DAY_SLOTS_FAILURE, error);
